@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.services {

  .list {
    @include rmax($xl) {
      max-width: 800px;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
    }

    @include rmax($sm) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    @include rmax($xl) {
      max-width: 300px;
      margin: 0 auto;
    }
    &__title {
      white-space: nowrap;
    }

    &__link {
      display: flex;
      // display: block;
    }
  }
}