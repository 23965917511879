@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
.text {
  &-center {
    text-align: center;

    &-lg {
      @include rmax($lg) {
        text-align: center;
      }
    }

    &-md {
      @include rmax($md) {
        text-align: center;
      }
    }

    &-sm {
      @include rmax($sm) {
        text-align: center;
      }
    }

    &-xs {
      @include rmax($xs) {
        text-align: center;
      }
    }
  }

  &-right {
    text-align: right;
  }
}

.text-up {
  text-transform: uppercase;
}

.text-low {
  text-transform: lowercase;
}

.text-secondary {
  font-family: var(--font-family-secondary);
}

.text-italic {
  font-style: italic;
}