@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

$makeResponsiveBreakpoint: $xl;

.nav-actions {
  gap: 14px;
  @include rmax($makeResponsiveBreakpoint) {
    flex-direction: column;
    align-items: center;
  }
}