@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;



$makeResponsiveBreakpoint: $xl;
$navResponsiveBg: #fff;
$topPos: 70px;

.nav {
  position: relative;
  border-radius: 10px 10px 0 0 ;
  background-color: #fff;
  padding: 15px;
  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: #000;
    // @include rmax(1400) {
    //   width: 100%;
    //   left: 0;
    //   right: 0;
    // }
  }

  // @include rmax(1400) {
  //   padding-inline: 0;
  // }

  @include rmax($sm) {
    justify-content: space-between;
  }
  &.opened {
    box-shadow: unset !important;
    .nav__inner {
      transform: translateY(0);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__inner {
    @include rmax($makeResponsiveBreakpoint) {
      padding: 45px 0 30px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: calc($topPos - 25px);
      flex-direction: column;
      background-color: $navResponsiveBg;
      border-radius: 0 0 20px 20px;
      transform: translateY(-200%);
      overflow-y: auto;
      z-index: -1;
    }

    @include rhmax(600) {
      height: calc(100svh - $topPos);
    }
  }

}