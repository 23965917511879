@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.home-hero {

  .hero__inner {

    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(370 / 1312 * 100%);
    background-image: url('../assets/images/home/hero.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

  }

}