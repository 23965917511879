@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.nav-menu {
  padding-left: 150px;
  gap: 32px;
  @include rmax($xl) {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    padding-left: 0;
    gap: 24px;
  }
  @include rhmax(600) {
    overflow-y: scroll;
  }
}