/* stylelint-disable declaration-no-important */
@use "../../../../scss/utils/functions"as*;
@use "../../../../scss/utils/mixins"as*;

// settings

$backgroundColor: var(--c-white);
$bgOnHover: var(--c-accent);

$textColor: var(--c-black);
$textOnHover: var(--c-white);

$borderRadius: 8px;
$borderColor: var(--c-accent);
$borderColorOnHover: var(--c-accent);

$fontWeigth: var(--fw-300);
$paddingY: 11px;
$paddingX: 32px;

$fontSize: 24px;
$letterSpacing: 0;
$lineHeight: 1.2;

$borderWidth: 1px;

$outlineWidth: 2px;
$outlineColor: var(--c-accent);


*[class].btn {
  display: block;
  width: fit-content;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  font: inherit ;
  font-weight: $fontWeigth;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  white-space: nowrap;
  text-align: center;
  color: $textColor;
  border: $borderWidth solid $borderColor;
  background-color: $backgroundColor;
  outline: $outlineWidth solid transparent;
  // align-self: center;
  text-transform: uppercase;
  transition-property: background-color, color, border-color, outline;
  transition-duration: 0.2s;

  @include rmax($md) {
    font-size: 18px;
    padding: 8px 18px;

  }

  &:focus {
    outline-color: $outlineColor;
    outline-offset: 3px;
  }

  @include hover {
    border-color: $borderColorOnHover;
    color: $textOnHover !important ;
    background-color: $bgOnHover;

    &::after {
      background-color: #fff;
    }
  }

  &-black {
    background-color: #000;
    color: #fff;
    border-color: #000;

    @include hover {
      border-color: #000;
      color: #000 !important ;
      background-color: #fff;

      &::after {
        background-color: #000;;
      }
    }
  }

  &-sm {
    padding: 6px 16px;
    font-size: 16px;
    line-height: 1.5;

  }

  &-arrow {
    display: flex;
    gap: 10px;
  }


}


button.formsapp-popup-button {
  color: $textColor !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &.btn-black {
    color: var(--c-white) !important;
  }
}