@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.customers {

  .list {
    gap: 51px;
    @include rmax($xl) {
      gap: 20px;
    }
    @include rmax($md) {
      flex-direction: column;
    }

    & > li {
      @include rmax($md) {
        width: 100%;
      }
    }

  }

  .card {
    max-width: 394px;
    @include rmax($md) {
      max-width: 550px;
      margin: 0 auto;
      text-align: center;
    }

    &__image {
      @include rmax($md) {
        margin-inline: auto;
      }
    }

  }
}