@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.accordions {

  .main-title {
    margin-bottom: 8px;
  }

  .list {
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
.accordion-card {
  border-bottom: 1px solid #000;
  summary {
    position: relative;
    cursor: pointer;
    background-color: #fff;
    padding: 32px 0;
    padding-right: 40px;
    font-size: 18px;
    line-height: 1.5;


    &::after {
      content: '+';
      display: block;
      position: absolute;
      font-weight: 200;
      right: 0;
      width: 24px;
      height: 24px;
      font-size: 48px;
      top: 5px;
      transition: 0.15s ease-in-out;
    }
  }

  &__body {
    background-color: #fff;
    line-height: 1;
    padding-right: 60px;
    padding-bottom: 32px;
  }

  &[open] {
      summary {
        padding-bottom: 16px;
        &::after {
          content: '-';
        }
      }
  }
}
