
@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.project-section {

  .back-link {
    flex-direction: row-reverse;
    &::after {
      transform: scaleX(-1);
    }
  }

  .inner {
    .container {
      max-width: 1278px;
      gap: 20px;

      @include rmax($xl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include rmax($lg) {
        max-width: 700px;
        grid-template-columns: 1fr;
      }
    }
  }

  .project-view {
    max-width: 557px;
    @include rmax($lg) {
      max-width: 100%
    }
  }

  .project-image {
    border-radius: 8px;
    @include size('margin-bottom', 72, 20);
    @include rmax($lg) {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }

  .review {
    align-items: center;
    h2 {
      font-weight: 300;
    }

    blockquote {
      font-size: 14px;
    }
    .circle {
      min-width: 80px;
      border-radius: 50%;

    }
  }

  .project-info {
    max-width: 627px;
    display: flex;
    flex-direction: column;
    @include rmax($lg) {
      max-width: 100%
    }
  }

  .project-description {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
  }

  .project-content {
    line-height: 1.1;

    p {
      margin-bottom: 24px;
    }

    ul {
      padding-left: 20px;
    }

    li {
      list-style-type: disc;
      margin-bottom: 24px;
    }
  }

  .actions {
    margin-top: auto;
  }
}