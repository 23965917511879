
@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.product-one-page {
  .back-link {
    flex-direction: row-reverse;
    &::after {
      transform: scaleX(-1);
    }
  }

  .container {
    gap: 64px;
    @include rmax($xl) {
      gap: 20px;
    }

    @include rmax($lg) {
      flex-direction: column;
    }
  }

  .product-sliders {
    flex-direction: row-reverse;
    gap: 32px;

    @include rmax($xl) {
      flex-direction: column-reverse;
    }
  }

  .thumbs-slider {
    width: 90px;
    height: 472px;

    @include rmax($xl) {
      width: 472px;
      height: 90px;
    }

    @include rmax($sm) {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .main-slider {
    width: 449px;
    height: 472px;
    @include rmax($sm) {
      width: 100%;
      height: auto;
    }
  }

  .product-card {
    max-width: 613px;
    margin-right: 60px;

    @include rmax($xl) {
      max-width: 100%;
      margin-right: 0;
    }

  }

  .card__text {
    margin-bottom: 30px;

    @include rmax($lg) {
      margin-bottom: 16px;
    }
  }

  .card__about {
    margin-bottom: 20px;
  }

  .card__chars {
    margin-bottom: 12px;
  }

  .card__group {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  .card__price {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;

    sup {
      font-size: 20px;
    }
  }
}