@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
@import "../base/global-vars";


.fz {
  &-xl {
    @include size('font-size', $fz-xl, $rfz-xl);
  }
  &-lg {
    @include size('font-size', $fz-lg, $rfz-lg);
  }

  &-md {
    @include size('font-size', $fz-md, $rfz-md);
  }

  &-xs {
    @include size('font-size', $fz-sm, $fz-sm);
  }
}