:root {
  --content-width: 1312px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --header-indents: 15px;
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Releway", sans-serif;
  --lineHeight: 1;
  --letterSpacing: 0;
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fz-text-normal: 16px;
  --c-white: #fff;
  --c-black: #000;
  --c-primary: #000;
  --c-secondary: #000;
  --c-light: #fffff6;
  --c-dark: #000;
  --c-gray: #8d8d8d;
  --c-accent: #000;
  --main-gradient: linear-gradient(89.93deg, #fff 0.04%, #9990 86.36%);
}

@font-face {
  src: url("../Inter-ExtraLight.ae65dcbb.woff2") format("woff2");
  font-family: Inter;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Inter-Light.7d8fe491.woff2") format("woff2");
  font-family: Inter;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Inter-Regular.a711948e.woff2") format("woff2");
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Raleway-Light.305afe08.woff2") format("woff2");
  font-family: Raleway;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Raleway-Regular.b4c7ea67.woff2") format("woff2");
  font-family: Raleway;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Raleway-Medium.4e32860e.woff2") format("woff2");
  font-family: Raleway;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  font-style: normal;
  font-size: var(--fz-text-normal);
  line-height: var(--lineHeight);
  letter-spacing: var(--letterSpacing);
  color: var(--c-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
}

.page__body {
  min-height: var(--vh);
  min-width: 320px;
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.wrapper {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
  position: relative;
  overflow-x: hidden;
}

.wrapper:before {
  content: "";
  z-index: -100;
  opacity: 0;
  background-color: #0000;
  transition: all .3s ease-in-out;
  position: fixed;
  inset: 0;
}

.open .wrapper:before {
  z-index: 20;
  background-color: var(--c-dark);
  opacity: .8;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.main-top {
  padding-top: 72px;
}

.title, .main-title, .card__title {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-size: calc(.740741vw + 21.3333px);
  font-weight: 500;
  line-height: 1.2;
}

@media (width >= 1440px) {
  .title, .main-title, .card__title {
    font-size: 32px;
  }
}

.fz-20 {
  font-size: 20px;
}

.fz-40 {
  font-size: 40px;
}

@media (width <= 992px) {
  .fz-40 {
    font-size: 30px;
  }
}

.link-arrow, .icon-arrow, .btn-arrow {
  text-transform: uppercase;
  align-items: center;
  gap: 5px;
  display: flex;
}

.link-arrow:after, .icon-arrow:after, .btn-arrow:after {
  content: "";
  background-color: #000;
  width: 12px;
  height: 12px;
  transition: all .3s ease-in-out;
  display: block;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.855 13.4573L0.832275 12.4431L11.3152 1.95166H2.92887L2.94591 0.54541H13.7357V11.3522H12.3124L12.3294 2.97439L1.855 13.4573Z' fill='black'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.855 13.4573L0.832275 12.4431L11.3152 1.95166H2.92887L2.94591 0.54541H13.7357V11.3522H12.3124L12.3294 2.97439L1.855 13.4573Z' fill='black'/%3E%3C/svg%3E%0A");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media (hover: hover) and (pointer: fine) {
  .link-arrow:hover:after, .icon-arrow:hover:after, .btn-arrow:hover:after {
    transform: rotate(45deg);
  }
}

.btn-arrow.btn-black:after, .link-arrow-white:after {
  background-color: #fff;
}

.bb-1 {
  border-bottom: 1px solid #000;
}

.bg-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 80.6748%;
  position: relative;
  overflow: hidden;
}

@media (width <= 768px) {
  .bg-card {
    padding-top: 270px;
  }
}

.bg-card:hover .card__content {
  opacity: 1;
}

.bg-card .card__content {
  opacity: 0;
  z-index: 10;
  background-color: #00000080;
  transition: all .3s ease-in-out;
  position: absolute;
  inset: 0;
}

.bg-primary {
  background-color: var(--c-primary);
}

.bg-secondary {
  background-color: var(--c-secondary);
}

.bg-light {
  background-color: var(--c-light);
}

.bg-dark {
  background-color: var(--c-dark);
}

.bg-accent {
  background-color: var(--c-accent);
}

.bg-white {
  background-color: var(--c-white);
}

.bg-gray {
  background-color: var(--c-gray);
}

.bg-nr {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

[class*="border"] {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-color-white {
  border-color: var(--c-white);
}

.border-color-black {
  border-color: var(--c-black);
}

.border-color-accent {
  border-color: var(--c-accent);
}

.border-color-light {
  border-color: var(--c-light);
}

.center {
  margin: 0 auto;
}

.center-abs {
  margin: 0 auto;
  left: 0;
  right: 0;
}

.color-white {
  color: var(--c-white);
}

.color-black {
  color: var(--c-black);
}

.color-inherit {
  color: inherit;
}

.color-primary {
  color: var(--c-primary);
}

.color-secondary {
  color: var(--c-secondary);
}

.color-light {
  color: var(--c-light);
}

.color-gray {
  color: var(--c-gray);
}

.color-dark {
  color: var(--c-dark);
}

.color-accent {
  color: var(--c-accent);
}

.color-green {
  color: var(--c-green);
}

.col-w-6 {
  width: 48%;
}

.col-w-3 {
  width: 31%;
}

.d-grid {
  display: grid;
}

.g-col-1 {
  grid-template-columns: 1fr;
}

.g-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

@media (width <= 992px) {
  .g-col-2.g-col-1-lg {
    grid-template-columns: 1fr;
  }
}

@media (width <= 768px) {
  .g-col-2.g-col-1-md {
    grid-template-columns: 1fr;
  }
}

@media (width <= 600px) {
  .g-col-2.g-col-1-sm {
    grid-template-columns: 1fr;
  }
}

.g-col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.g-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (width <= 992px) {
  .g-col-4.g-col-2-lg {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 768px) {
  .g-col-4.g-col-2-md {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 600px) {
  .g-col-4.g-col-2-sm {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 768px) {
  .g-col-4.g-col-1-md {
    grid-template-columns: 1fr;
  }
}

@media (width <= 600px) {
  .g-col-4.g-col-1-sm {
    grid-template-columns: 1fr;
  }
}

.flex {
  display: flex;
}

.flex.col-2 > * {
  flex: 1 0;
  width: 48%;
}

@media (width <= 600px) {
  .flex.col-2.col-1-sm {
    flex-wrap: wrap;
  }

  .flex.col-2.col-1-sm > * {
    flex: 1 0 auto;
    width: 100% !important;
  }
}

@media (width <= 768px) {
  .flex.col-2.col-1-md {
    flex-wrap: wrap;
  }

  .flex.col-2.col-1-md > * {
    flex: 1 0 auto;
    width: 100%;
  }
}

.flex.col-3 > * {
  flex: 1 0 auto;
  width: 31%;
}

@media (width <= 1200px) {
  .flex.col-3.col-2-xl {
    flex-wrap: wrap;
  }

  .flex.col-3.col-2-xl > * {
    flex: 1 0 auto;
    width: 48% !important;
  }
}

@media (width <= 992px) {
  .flex.col-3.col-2-lg {
    flex-wrap: wrap;
  }

  .flex.col-3.col-2-lg > * {
    flex: 1 0 auto;
    width: 48% !important;
  }
}

@media (width <= 768px) {
  .flex.col-3.col-2-lg.col-1-md {
    flex-wrap: wrap;
  }

  .flex.col-3.col-2-lg.col-1-md > * {
    flex: 1 0 auto;
    width: 100% !important;
  }
}

@media (width <= 992px) {
  .flex.col-3.col-1-lg {
    flex-wrap: wrap;
  }

  .flex.col-3.col-1-lg > * {
    flex: 1 0 auto;
    width: 100%;
  }
}

@media (width <= 768px) {
  .flex.col-3.col-1-md {
    flex-wrap: wrap;
  }

  .flex.col-3.col-1-md > * {
    flex: 1 0 auto;
    width: 100% !important;
  }
}

@media (width <= 600px) {
  .flex.col-3.col-1-sm {
    flex-wrap: wrap;
  }

  .flex.col-3.col-1-sm > * {
    flex: 1 0 auto;
    width: 100% !important;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-r-reverse {
  flex-direction: row-reverse;
}

.flex-c-reverse {
  flex-direction: column-reverse;
}

.flex-grow {
  flex: 1 0 auto;
}

.align-center {
  align-items: center;
}

@media (width <= 992px) {
  .align-center-lg {
    align-items: center;
  }
}

.align-stretch {
  align-items: stretch;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

@media (width <= 992px) {
  [class^="align"].align-center-lg {
    align-items: center;
  }
}

@media (width <= 768px) {
  [class^="align"].align-center-md, [class^="align"].align-center-sm {
    align-items: center;
  }
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

@media (width <= 992px) {
  [class*="justify"].justify-center-lg {
    justify-content: center;
  }
}

@media (width <= 768px) {
  [class*="justify"].justify-center-md {
    justify-content: center;
  }
}

@media (width <= 600px) {
  [class*="justify"].justify-center-sm {
    justify-content: center;
  }
}

@media (width <= 480px) {
  [class*="justify"].justify-center-xs {
    justify-content: center;
  }
}

.font-primary {
  font-family: var(--font-family-primary);
}

.font-secondary {
  font-family: var(--font-family-secondary);
}

.fz-xl {
  font-size: calc(.740741vw + 21.3333px);
}

@media (width >= 1440px) {
  .fz-xl {
    font-size: 32px;
  }
}

.fz-lg {
  font-size: calc(.37037vw + 18.6667px);
}

@media (width >= 1440px) {
  .fz-lg {
    font-size: 24px;
  }
}

.fz-md {
  font-size: 18px;
}

.fz-xs {
  font-size: 14px;
}

.fw-100, .fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.gap-xxl {
  gap: 5.55556vw;
}

@media (width >= 1440px) {
  .gap-xxl {
    gap: 80px;
  }
}

.gap-xl {
  gap: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .gap-xl {
    gap: 64px;
  }
}

.gap-lg {
  gap: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .gap-lg {
    gap: 48px;
  }
}

.gap-md {
  gap: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .gap-md {
    gap: 32px;
  }
}

.gap-sm {
  gap: 16px;
}

@media (width <= 768px) {
  .gap-sm {
    gap: 16px;
  }
}

.gap-xs, .gap-xxs {
  gap: 8px;
}

.gap-6 {
  gap: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .gap-6 {
    gap: 60px;
  }
}

.gap-5 {
  gap: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .gap-5 {
    gap: 50px;
  }
}

.gap-4 {
  gap: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .gap-4 {
    gap: 40px;
  }
}

.gap-3 {
  gap: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .gap-3 {
    gap: 30px;
  }
}

.gap-2 {
  gap: 20px;
}

.gap-1 {
  gap: 10px;
}

.c-gap-xxl {
  column-gap: 5.55556vw;
}

@media (width >= 1440px) {
  .c-gap-xxl {
    column-gap: 80px;
  }
}

.c-gap-xl {
  column-gap: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .c-gap-xl {
    column-gap: 64px;
  }
}

.c-gap-lg {
  column-gap: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .c-gap-lg {
    column-gap: 48px;
  }
}

.c-gap-md {
  column-gap: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .c-gap-md {
    column-gap: 32px;
  }
}

.c-gap-sm {
  column-gap: 16px;
}

@media (width <= 768px) {
  .c-gap-sm {
    column-gap: 16px;
  }
}

.c-gap-xs, .c-gap-xxs {
  column-gap: 8px;
}

.c-gap-6 {
  column-gap: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .c-gap-6 {
    column-gap: 60px;
  }
}

.c-gap-5 {
  column-gap: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .c-gap-5 {
    column-gap: 50px;
  }
}

.c-gap-4 {
  column-gap: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .c-gap-4 {
    column-gap: 40px;
  }
}

.c-gap-3 {
  column-gap: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .c-gap-3 {
    column-gap: 30px;
  }
}

.c-gap-2 {
  column-gap: 20px;
}

.c-gap-1 {
  column-gap: 10px;
}

.r-gap-xxl {
  row-gap: 5.55556vw;
}

@media (width >= 1440px) {
  .r-gap-xxl {
    row-gap: 80px;
  }
}

.r-gap-xl {
  row-gap: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .r-gap-xl {
    row-gap: 64px;
  }
}

.r-gap-lg {
  row-gap: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .r-gap-lg {
    row-gap: 48px;
  }
}

.r-gap-md {
  row-gap: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .r-gap-md {
    row-gap: 32px;
  }
}

.r-gap-sm {
  row-gap: 16px;
}

@media (width <= 768px) {
  .r-gap-sm {
    row-gap: 16px;
  }
}

.r-gap-xs, .r-gap-xxs {
  row-gap: 8px;
}

.r-gap-6 {
  row-gap: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .r-gap-6 {
    row-gap: 60px;
  }
}

.r-gap-5 {
  row-gap: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .r-gap-5 {
    row-gap: 50px;
  }
}

.r-gap-4 {
  row-gap: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .r-gap-4 {
    row-gap: 40px;
  }
}

.r-gap-3 {
  row-gap: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .r-gap-3 {
    row-gap: 30px;
  }
}

.r-gap-2 {
  row-gap: 20px;
}

.r-gap-1 {
  row-gap: 10px;
}

.gradient-main {
  background-image: var(--main-gradient);
}

.h-max {
  height: 100%;
}

.icon:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.icon-bg:before {
  width: var(--main-icon-size);
  height: var(--main-icon-size);
  aspect-ratio: 1;
}

.icon-mask:after {
  content: "";
  background-color: var(--c-accent);
  aspect-ratio: 1;
  min-width: 24px;
  height: 24px;
  display: block;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.icon-mask-bg:before {
  width: var(--main-icon-size);
  height: var(--main-icon-size);
  aspect-ratio: 1;
}

.ls-02 {
  letter-spacing: .02em;
}

.ls-01 {
  letter-spacing: .01em;
}

.-ls-02 {
  letter-spacing: -.02em;
}

.-ls-01 {
  letter-spacing: -.01em;
}

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-115 {
  line-height: 1.15;
}

.lh-120 {
  line-height: 1.2;
}

.lh-125 {
  line-height: 1.25;
}

.lh-130 {
  line-height: 1.3;
}

.lh-135 {
  line-height: 1.35;
}

.lh-140 {
  line-height: 1.4;
}

.lh-145 {
  line-height: 1.45;
}

.lh-150 {
  line-height: 1.5;
}

.lh-155 {
  line-height: 1.55;
}

.lh-160 {
  line-height: 1.6;
}

.lh-165 {
  line-height: 1.65;
}

.lh-170 {
  line-height: 1.7;
}

.lh-175 {
  line-height: 1.75;
}

.mb-xxl {
  margin-bottom: 5.55556vw;
}

@media (width >= 1440px) {
  .mb-xxl {
    margin-bottom: 80px;
  }
}

.mb-xl {
  margin-bottom: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .mb-xl {
    margin-bottom: 64px;
  }
}

.mb-lg {
  margin-bottom: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .mb-lg {
    margin-bottom: 48px;
  }
}

.mb-md {
  margin-bottom: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .mb-md {
    margin-bottom: 32px;
  }
}

.mb-sm {
  margin-bottom: 16px;
}

@media (width <= 768px) {
  .mb-sm {
    margin-bottom: 16px;
  }
}

.mb-xs, .mb-xxs {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .mb-6 {
    margin-bottom: 60px;
  }
}

.mb-5 {
  margin-bottom: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .mb-5 {
    margin-bottom: 50px;
  }
}

.mb-4 {
  margin-bottom: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .mb-4 {
    margin-bottom: 40px;
  }
}

.mb-3 {
  margin-bottom: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .mb-3 {
    margin-bottom: 30px;
  }
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-05 {
  margin-bottom: 5px;
}

.mr-xxl {
  margin-right: 5.55556vw;
}

@media (width >= 1440px) {
  .mr-xxl {
    margin-right: 80px;
  }
}

.mr-xl {
  margin-right: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .mr-xl {
    margin-right: 64px;
  }
}

.mr-lg {
  margin-right: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .mr-lg {
    margin-right: 48px;
  }
}

.mr-md {
  margin-right: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .mr-md {
    margin-right: 32px;
  }
}

.mr-sm {
  margin-right: 16px;
}

@media (width <= 768px) {
  .mr-sm {
    margin-right: 16px;
  }
}

.mr-xs, .mr-xxs {
  margin-right: 8px;
}

.mr-6 {
  margin-right: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .mr-6 {
    margin-right: 60px;
  }
}

.mr-5 {
  margin-right: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .mr-5 {
    margin-right: 50px;
  }
}

.mr-4 {
  margin-right: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .mr-4 {
    margin-right: 40px;
  }
}

.mr-3 {
  margin-right: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .mr-3 {
    margin-right: 30px;
  }
}

.mr-2 {
  margin-right: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-05 {
  margin-right: 5px;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.op-80 {
  opacity: .8;
}

.op-70 {
  opacity: .7;
}

.op-60 {
  opacity: .6;
}

.op-50 {
  opacity: .5;
}

.op-40 {
  opacity: .4;
}

.op-20 {
  opacity: .2;
}

.no-hover {
  pointer-events: none;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.with-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overflow {
  overflow: hidden;
}

.overflow-y {
  overflow-y: hidden;
}

.overflow-x {
  overflow-x: hidden;
}

.overlay {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

.overlay-linear:before {
  background-image: var(--dark-gradient);
}

.overlay-radial:before {
  background-image: var(--radial-gradient);
}

.overlay-dark:before {
  background-color: var(--c-dark);
}

.overlay-primary:before {
  background-color: var(--c-primary);
}

.overlay-accent:before {
  background-color: var(--c-accent);
}

.overlay-op-20:before, .overlay-op-40:before, .overlay-op-60:before {
  opacity: .2;
}

.overlay-op-80:before {
  opacity: .8;
}

.overlay-white:before {
  background-color: #fff;
}

.overlay-black:before {
  background-color: #000;
}

.py-xxl {
  padding-top: 5.55556vw;
  padding-bottom: 5.55556vw;
}

@media (width >= 1440px) {
  .py-xxl {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.py-xl {
  padding-top: calc(4.07407vw + 5.33333px);
  padding-bottom: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .py-xl {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.py-lg {
  padding-top: calc(2.59259vw + 10.6667px);
  padding-bottom: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .py-lg {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.py-md {
  padding-top: calc(1.11111vw + 16px);
  padding-bottom: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .py-md {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.py-sm {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (width <= 768px) {
  .py-sm {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.py-xs, .py-xxs {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-6 {
  padding-top: calc(3.7037vw + 6.66667px);
  padding-bottom: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .py-6 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.py-5 {
  padding-top: calc(2.77778vw + 10px);
  padding-bottom: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.py-4 {
  padding-top: calc(1.85185vw + 13.3333px);
  padding-bottom: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .py-4 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (width <= 768px) {
  .py-3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-xxl {
  padding-left: 5.55556vw;
  padding-right: 5.55556vw;
}

@media (width >= 1440px) {
  .px-xxl {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.px-xl {
  padding-left: calc(4.07407vw + 5.33333px);
  padding-right: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .px-xl {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.px-lg {
  padding-left: calc(2.59259vw + 10.6667px);
  padding-right: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .px-lg {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.px-md {
  padding-left: calc(1.11111vw + 16px);
  padding-right: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .px-md {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.px-sm {
  padding-left: 16px;
  padding-right: 16px;
}

@media (width <= 768px) {
  .px-sm {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.px-xs, .px-xxs {
  padding-left: 8px;
  padding-right: 8px;
}

.px-6 {
  padding-left: calc(3.7037vw + 6.66667px);
  padding-right: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .px-6 {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.px-5 {
  padding-left: calc(2.77778vw + 10px);
  padding-right: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.px-4 {
  padding-left: calc(1.85185vw + 13.3333px);
  padding-right: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .px-4 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

@media (width <= 768px) {
  .px-3 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.pb-xxl {
  padding-bottom: 5.55556vw;
}

@media (width >= 1440px) {
  .pb-xxl {
    padding-bottom: 80px;
  }
}

.pb-xl {
  padding-bottom: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .pb-xl {
    padding-bottom: 64px;
  }
}

.pb-lg {
  padding-bottom: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .pb-lg {
    padding-bottom: 48px;
  }
}

.pb-md {
  padding-bottom: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .pb-md {
    padding-bottom: 32px;
  }
}

.pb-sm {
  padding-bottom: 16px;
}

@media (width <= 768px) {
  .pb-sm {
    padding-bottom: 16px;
  }
}

.pb-xs, .pb-xxs {
  padding-bottom: 8px;
}

.pb-6 {
  padding-bottom: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .pb-6 {
    padding-bottom: 60px;
  }
}

.pb-5 {
  padding-bottom: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .pb-5 {
    padding-bottom: 50px;
  }
}

.pb-4 {
  padding-bottom: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .pb-4 {
    padding-bottom: 40px;
  }
}

.pb-3 {
  padding-bottom: 30px;
}

@media (width <= 768px) {
  .pb-3 {
    padding-bottom: 20px;
  }
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pt-xxl {
  padding-top: 5.55556vw;
}

@media (width >= 1440px) {
  .pt-xxl {
    padding-top: 80px;
  }
}

.pt-xl {
  padding-top: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .pt-xl {
    padding-top: 64px;
  }
}

.pt-lg {
  padding-top: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .pt-lg {
    padding-top: 48px;
  }
}

.pt-md {
  padding-top: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .pt-md {
    padding-top: 32px;
  }
}

.pt-sm {
  padding-top: 16px;
}

@media (width <= 768px) {
  .pt-sm {
    padding-top: 16px;
  }
}

.pt-xs, .pt-xxs {
  padding-top: 8px;
}

.pt-6 {
  padding-top: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .pt-6 {
    padding-top: 60px;
  }
}

.pt-5 {
  padding-top: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .pt-5 {
    padding-top: 50px;
  }
}

.pt-4 {
  padding-top: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .pt-4 {
    padding-top: 40px;
  }
}

.pt-3 {
  padding-top: 30px;
}

@media (width <= 768px) {
  .pt-3 {
    padding-top: 20px;
  }
}

.pt-2 {
  padding-top: 20px;
}

.pt-1 {
  padding-top: 10px;
}

.pa-xxl {
  padding: 5.55556vw;
}

@media (width >= 1440px) {
  .pa-xxl {
    padding: 80px;
  }
}

.pa-xl {
  padding: calc(4.07407vw + 5.33333px);
}

@media (width >= 1440px) {
  .pa-xl {
    padding: 64px;
  }
}

.pa-lg {
  padding: calc(2.59259vw + 10.6667px);
}

@media (width >= 1440px) {
  .pa-lg {
    padding: 48px;
  }
}

.pa-md {
  padding: calc(1.11111vw + 16px);
}

@media (width >= 1440px) {
  .pa-md {
    padding: 32px;
  }
}

.pa-sm {
  padding: 16px;
}

@media (width <= 768px) {
  .pa-sm {
    padding: 16px;
  }
}

.pa-xs, .pa-xxs {
  padding: 8px;
}

.pa-6 {
  padding: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .pa-6 {
    padding: 60px;
  }
}

.pa-5 {
  padding: calc(2.77778vw + 10px);
}

@media (width >= 1440px) {
  .pa-5 {
    padding: 50px;
  }
}

.pa-4 {
  padding: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .pa-4 {
    padding: 40px;
  }
}

.pa-3 {
  padding: 30px;
}

@media (width <= 768px) {
  .pa-3 {
    padding: 20px;
  }
}

.pa-2 {
  padding: 20px;
}

.pa-1 {
  padding: 10px;
}

.dot-before, .dot-after {
  position: relative;
}

.dot-before:before, .dot-before:after, .dot-after:before, .dot-after:after {
  background-color: var(--c-black);
  border-radius: 3px;
  width: 3px;
  height: 3px;
  display: block;
}

.dot-after:after, .dot-before:before {
  content: "";
}

.dot-accent:before, .dot-accent:after {
  background-color: var(--c-accent);
}

.dot-lg:before, .dot-lg:after {
  width: 6px;
  height: 6px;
}

.num-after[data-number], .num-after [data-number], .num-before[data-number], .num-before [data-number] {
  position: relative;
}

.num-after[data-number]:before, .num-after[data-number]:after, .num-after [data-number]:before, .num-after [data-number]:after, .num-before[data-number]:before, .num-before[data-number]:after, .num-before [data-number]:before, .num-before [data-number]:after {
  color: var(--c-black);
  display: block;
}

.num-after[data-number]:after, .num-after [data-number]:after, .num-before[data-number]:before, .num-before [data-number]:before {
  content: attr(data-number);
}

.num-accent[data-number]:before, .num-accent[data-number]:after, .num-accent [data-number]:before, .num-accent [data-number]:after {
  color: var(--c-accent);
}

.radius-lg {
  border-radius: var(--radius-lg);
  border-radius: calc(.37037vw + 18.6667px);
}

@media (width >= 1440px) {
  .radius-lg {
    border-radius: 24px;
  }
}

.radius-md {
  border-radius: calc(17.3333px - .37037vw);
}

@media (width >= 1440px) {
  .radius-md {
    border-radius: 12px;
  }
}

.radius-sm {
  border-radius: 8px;
}

.radius-4 {
  border-radius: calc(1.85185vw + 13.3333px);
}

@media (width >= 1440px) {
  .radius-4 {
    border-radius: 40px;
  }
}

.radius-3 {
  border-radius: calc(1.38889vw + 10px);
}

@media (width >= 1440px) {
  .radius-3 {
    border-radius: 30px;
  }
}

.radius-2 {
  border-radius: calc(.925926vw + 6.66667px);
}

@media (width >= 1440px) {
  .radius-2 {
    border-radius: 20px;
  }
}

.radius-1 {
  border-radius: 10px;
}

.radius-circle {
  border-radius: 50%;
}

.text-center {
  text-align: center;
}

@media (width <= 992px) {
  .text-center-lg {
    text-align: center;
  }
}

@media (width <= 768px) {
  .text-center-md {
    text-align: center;
  }
}

@media (width <= 600px) {
  .text-center-sm {
    text-align: center;
  }
}

@media (width <= 480px) {
  .text-center-xs {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-up {
  text-transform: uppercase;
}

.text-low {
  text-transform: lowercase;
}

.text-secondary {
  font-family: var(--font-family-secondary);
}

.text-italic {
  font-style: italic;
}

.title-xxl {
  font-size: calc(5.55556vw + 20px);
}

@media (width >= 1440px) {
  .title-xxl {
    font-size: 100px;
  }
}

.title-xl {
  font-size: calc(3.7037vw + 22.6667px);
}

@media (width >= 1440px) {
  .title-xl {
    font-size: 76px;
  }
}

.title-lg {
  font-size: calc(1.11111vw + 26px);
}

@media (width >= 1440px) {
  .title-lg {
    font-size: 42px;
  }
}

.title-md {
  font-size: calc(.555556vw + 24px);
}

@media (width >= 1440px) {
  .title-md {
    font-size: 32px;
  }
}

.title-sm {
  font-size: 24px;
}

@media (width <= 600px) {
  .title-sm {
    font-size: 24px;
  }
}

.title-xs {
  font-size: 16px;
}

@media (width <= 480px) {
  .title-xs {
    font-size: 16px;
  }
}

.title-xxs {
  font-size: 16px;
}

.w-max {
  width: 100%;
}

@media (width <= 1200px) {
  .hero-img .inner {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (width <= 992px) {
  .hero-img .inner {
    grid-template-columns: 1fr;
  }

  .hero-img .content {
    gap: 0;
  }
}

.hero-img .main-title {
  margin-bottom: calc(.740741vw + 13.3333px);
  font-size: calc(1.66667vw + 18px);
  line-height: 1.45;
}

@media (width >= 1440px) {
  .hero-img .main-title {
    margin-bottom: 24px;
    font-size: 42px;
  }
}

.hero-img .main-text {
  max-width: 400px;
  margin-bottom: calc(.740741vw + 13.3333px);
}

@media (width >= 1440px) {
  .hero-img .main-text {
    margin-bottom: 24px;
  }
}

@media (width <= 992px) {
  .hero-img .image {
    object-fit: cover;
    height: 250px;
  }

  section .head {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  section .head .link-arrow {
    margin: 0;
  }
}

section .card__title + .card__link {
  padding-bottom: 21px;
}

@media (width <= 992px) {
  section .back-link {
    margin-right: auto;
  }
}

.home-hero .hero__inner {
  background-image: url("../assets/images/home/hero.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 0;
  padding-top: 28.2012%;
  position: relative;
}

@media (width <= 1200px) {
  .services .list {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
    margin: 0 auto;
  }
}

@media (width <= 600px) {
  .services .list {
    grid-template-columns: 1fr;
  }
}

@media (width <= 1200px) {
  .services .card {
    max-width: 300px;
    margin: 0 auto;
  }
}

.services .card__title {
  white-space: nowrap;
}

.services .card__link {
  display: flex;
}

.about-us {
  padding-bottom: calc(2.77778vw + 20px);
}

@media (width >= 1440px) {
  .about-us {
    padding-bottom: 60px;
  }
}

.about-us .container {
  max-width: 1200px;
}

@media (width <= 992px) {
  .about-us .first {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.about-us .first .content {
  margin-bottom: 0;
}

.about-us .second {
  padding-bottom: calc(4.62963vw + 13.3333px);
}

@media (width >= 1440px) {
  .about-us .second {
    padding-bottom: 80px;
  }
}

@media (width <= 992px) {
  .about-us .second {
    flex-direction: column;
  }
}

.about-us .second .text {
  max-width: 520px;
  font-weight: 800;
}

@media (width <= 992px) {
  .about-us .second .text {
    max-width: 760px;
  }
}

.about-us .list {
  max-width: 1020px;
  margin-inline: auto;
}

.about-us .card {
  max-width: 315px;
}

@media (width <= 768px) {
  .about-us .card {
    max-width: 600px;
    margin: 0 auto;
  }
}

.about-us .card__title {
  letter-spacing: -.018em;
  margin-bottom: 18px;
  font-size: calc(1.66667vw + 24px);
  font-weight: 800;
  line-height: 1.2;
  display: block;
}

@media (width >= 1440px) {
  .about-us .card__title {
    font-size: 48px;
  }
}

.about-us .card__text {
  line-height: 1.35;
  font-style: 20px;
  font-weight: 800;
}

.customers .list {
  gap: 51px;
}

@media (width <= 1200px) {
  .customers .list {
    gap: 20px;
  }
}

@media (width <= 768px) {
  .customers .list {
    flex-direction: column;
  }

  .customers .list > li {
    width: 100%;
  }
}

.customers .card {
  max-width: 394px;
}

@media (width <= 768px) {
  .customers .card {
    text-align: center;
    max-width: 550px;
    margin: 0 auto;
  }

  .customers .card__image {
    margin-inline: auto;
  }
}

.s-services .list > li:not(:last-child) {
  margin-bottom: 40px;
}

@media (width <= 768px) {
  .s-services .card {
    flex-direction: column;
  }
}

.s-services .card__inner {
  gap: 40px;
  display: flex;
}

@media (width <= 992px) {
  .s-services .card__inner {
    flex-direction: column;
    gap: 20px;
  }
}

.s-services .card__image {
  object-fit: contain;
  object-position: top center;
}

@media (width <= 768px) {
  .s-services .card__image {
    width: 300px;
  }
}

.s-services .card__title, .s-services .card__text {
  margin-bottom: 21px;
}

.s-services .card__content {
  min-width: 310px;
}

@media (width <= 768px) {
  .s-services .card__content {
    min-width: 100px;
  }
}

.s-services .card__description {
  max-width: 240px;
}

@media (width <= 992px) {
  .s-services .card__description {
    max-width: 100%;
  }
}

.s-services .card__list {
  padding-top: 50px;
}

@media (width <= 992px) {
  .s-services .card__list {
    padding-top: 0;
    padding-left: 20px;
  }
}

.s-services .card__list li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.hero-catalog .main-title {
  margin-bottom: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .hero-catalog .main-title {
    margin-bottom: 60px;
  }
}

.accordions .main-title, .accordions .list li:not(:last-child) {
  margin-bottom: 8px;
}

.accordion-card {
  border-bottom: 1px solid #000;
}

.accordion-card summary {
  cursor: pointer;
  background-color: #fff;
  padding: 32px 40px 32px 0;
  font-size: 18px;
  line-height: 1.5;
  position: relative;
}

.accordion-card summary:after {
  content: "+";
  width: 24px;
  height: 24px;
  font-size: 48px;
  font-weight: 200;
  transition: all .15s ease-in-out;
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
}

.accordion-card__body {
  background-color: #fff;
  padding-bottom: 32px;
  padding-right: 60px;
  line-height: 1;
}

.accordion-card[open] summary {
  padding-bottom: 16px;
}

.accordion-card[open] summary:after {
  content: "-";
}

.hero-portfolio .main-title {
  letter-spacing: -.07em;
  margin-bottom: calc(3.7037vw + 6.66667px);
}

@media (width >= 1440px) {
  .hero-portfolio .main-title {
    margin-bottom: 60px;
  }
}

.subscribe {
  background-image: url("../assets/images/home/get.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 490px;
  padding-block: 129px;
}

@media (width <= 992px) {
  .subscribe {
    height: initial;
    padding-block: 60px;
  }

  .subscribe .inner {
    justify-content: center;
  }
}

.subscribe .content {
  max-width: 488px;
  margin-right: 60px;
}

@media (width <= 992px) {
  .subscribe .content {
    background-color: #ffffffb3;
    border-radius: 8px;
    max-width: max-content;
    margin-right: 0;
    padding: 16px;
  }
}

.subscribe label {
  font-size: 24px;
}

.subscribe .form-group {
  flex-wrap: nowrap;
}

@media (width <= 992px) {
  .subscribe .form-group {
    flex-wrap: wrap;
  }
}

.subscribe .input {
  background-color: #fff;
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  width: 278px;
  padding: 9px 16px;
  font-size: 20px;
  font-style: italic;
  line-height: 1.5;
}

@media (width <= 992px) {
  .subscribe .input {
    width: auto;
  }
}

.subscribe .btn {
  font-size: 24px !important;
}

.project-section .back-link {
  flex-direction: row-reverse;
}

.project-section .back-link:after {
  transform: scaleX(-1);
}

.project-section .inner .container {
  gap: 20px;
  max-width: 1278px;
}

@media (width <= 1200px) {
  .project-section .inner .container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (width <= 992px) {
  .project-section .inner .container {
    grid-template-columns: 1fr;
    max-width: 700px;
  }
}

.project-section .project-view {
  max-width: 557px;
}

@media (width <= 992px) {
  .project-section .project-view {
    max-width: 100%;
  }
}

.project-section .project-image {
  border-radius: 8px;
  margin-bottom: calc(4.81481vw + 2.66667px);
}

@media (width >= 1440px) {
  .project-section .project-image {
    margin-bottom: 72px;
  }
}

@media (width <= 992px) {
  .project-section .project-image {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
}

.project-section .review {
  align-items: center;
}

.project-section .review h2 {
  font-weight: 300;
}

.project-section .review blockquote {
  font-size: 14px;
}

.project-section .review .circle {
  border-radius: 50%;
  min-width: 80px;
}

.project-section .project-info {
  flex-direction: column;
  max-width: 627px;
  display: flex;
}

@media (width <= 992px) {
  .project-section .project-info {
    max-width: 100%;
  }
}

.project-section .project-description {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.2;
}

.project-section .project-content {
  line-height: 1.1;
}

.project-section .project-content p {
  margin-bottom: 24px;
}

.project-section .project-content ul {
  padding-left: 20px;
}

.project-section .project-content li {
  margin-bottom: 24px;
  list-style-type: disc;
}

.project-section .actions {
  margin-top: auto;
}

.product-one-page .back-link {
  flex-direction: row-reverse;
}

.product-one-page .back-link:after {
  transform: scaleX(-1);
}

.product-one-page .container {
  gap: 64px;
}

@media (width <= 1200px) {
  .product-one-page .container {
    gap: 20px;
  }
}

@media (width <= 992px) {
  .product-one-page .container {
    flex-direction: column;
  }
}

.product-one-page .product-sliders {
  flex-direction: row-reverse;
  gap: 32px;
}

@media (width <= 1200px) {
  .product-one-page .product-sliders {
    flex-direction: column-reverse;
  }
}

.product-one-page .thumbs-slider {
  width: 90px;
  height: 472px;
}

@media (width <= 1200px) {
  .product-one-page .thumbs-slider {
    width: 472px;
    height: 90px;
  }
}

@media (width <= 600px) {
  .product-one-page .thumbs-slider {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.product-one-page .main-slider {
  width: 449px;
  height: 472px;
}

@media (width <= 600px) {
  .product-one-page .main-slider {
    width: 100%;
    height: auto;
  }
}

.product-one-page .product-card {
  max-width: 613px;
  margin-right: 60px;
}

@media (width <= 1200px) {
  .product-one-page .product-card {
    max-width: 100%;
    margin-right: 0;
  }
}

.product-one-page .card__text {
  margin-bottom: 30px;
}

@media (width <= 992px) {
  .product-one-page .card__text {
    margin-bottom: 16px;
  }
}

.product-one-page .card__about {
  margin-bottom: 20px;
}

.product-one-page .card__chars {
  margin-bottom: 12px;
}

.product-one-page .card__group {
  flex-wrap: wrap;
  gap: 3px;
  display: flex;
}

.product-one-page .card__group:not(:last-of-type) {
  margin-bottom: 16px;
}

.product-one-page .card__price {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
}

.product-one-page .card__price sup {
  font-size: 20px;
}

.policy .container {
  max-width: 1246px;
}

.policy h1 {
  text-align: center;
  margin-bottom: calc(2.77778vw + 20px);
  font-size: calc(.925926vw + 26.6667px);
  font-weight: 500;
}

@media (width >= 1440px) {
  .policy h1 {
    margin-bottom: 60px;
    font-size: 40px;
  }
}

.policy__content {
  margin-bottom: 48px;
  font-size: 20px;
  line-height: 1.1;
}

.policy__content h2 {
  text-transform: uppercase;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
}

.policy__content p {
  margin-bottom: 20px;
}

.policy__content ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.policy__content ul li::marker {
  content: "✓ ";
  margin-right: 5px;
  display: inline-block;
}

[class].btn {
  font: inherit;
  font-weight: var(--fw-300);
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  color: var(--c-black);
  border: 1px solid var(--c-accent);
  background-color: var(--c-white);
  text-transform: uppercase;
  border-radius: 8px;
  outline: 2px solid #0000;
  width: fit-content;
  padding: 11px 32px;
  font-size: 24px;
  line-height: 1.2;
  transition-property: background-color, color, border-color, outline;
  transition-duration: .2s;
  display: block;
}

@media (width <= 768px) {
  [class].btn {
    padding: 8px 18px;
    font-size: 18px;
  }
}

[class].btn:focus {
  outline-color: var(--c-accent);
  outline-offset: 3px;
}

@media (hover: hover) and (pointer: fine) {
  [class].btn:hover {
    border-color: var(--c-accent);
    background-color: var(--c-accent);
    color: var(--c-white) !important;
  }

  [class].btn:hover:after {
    background-color: #fff;
  }
}

[class].btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

@media (hover: hover) and (pointer: fine) {
  [class].btn-black:hover {
    background-color: #fff;
    border-color: #000;
    color: #000 !important;
  }

  [class].btn-black:hover:after {
    background-color: #000;
  }
}

[class].btn-sm {
  padding: 6px 16px;
  font-size: 16px;
  line-height: 1.5;
}

[class].btn-arrow {
  gap: 10px;
  display: flex;
}

button.formsapp-popup-button {
  color: var(--c-black) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

button.formsapp-popup-button.btn-black {
  color: var(--c-white) !important;
}

.burger {
  z-index: 10;
  color: var(--c-accent);
  cursor: pointer;
  min-width: 36px;
  height: 30px;
  display: none;
  position: relative;
}

@media (width <= 1200px) {
  .burger {
    display: block;
  }
}

.burger__line {
  background-color: currentColor;
  width: 100%;
  height: 2px;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger:before, .burger:after {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 2px;
  transition: transform .3s ease-in-out, top .3s ease-in-out;
  position: absolute;
  left: 0;
}

.burger:before {
  border-radius: 2px 2px 0 0;
  top: 0;
}

.burger:after {
  border-radius: 0 0 2px 2px;
  top: calc(100% - 2px);
}

.page.open .burger .burger__line {
  background-color: var(--c-accent);
  transition: transform .3s ease-in-out;
  transform: scale(0);
}

.page.open .burger:before, .page.open .burger:after {
  background-color: var(--c-accent);
  border-radius: 0;
  transition: transform .3s ease-in-out, top .3s ease-in-out;
  top: 50%;
  transform: rotate(45deg);
}

.page.open .burger:after {
  transform: rotate(-45deg);
}

.card__image {
  object-fit: cover;
  width: auto;
}

.header {
  z-index: 20;
  background-color: #0000;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  transition-property: transform, padding, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .container {
  max-width: 1372px;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.nav {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  position: relative;
}

.nav:after {
  content: "";
  background-color: #000;
  width: calc(100% - 30px);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}

@media (width <= 600px) {
  .nav {
    justify-content: space-between;
  }
}

.nav.opened {
  box-shadow: unset !important;
}

.nav.opened .nav__inner {
  transition: transform .2s ease-in-out;
  transform: translateY(0);
}

@media (width <= 1200px) {
  .nav__inner {
    z-index: -1;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    flex-direction: column;
    width: 100%;
    padding: 45px 0 30px;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    overflow-y: auto;
    transform: translateY(-200%);
  }
}

@media (height <= 600px) {
  .nav__inner {
    height: calc(100svh - 70px);
  }
}

.logo {
  outline: 2px solid #0000;
  width: fit-content;
  transition: all .3s ease-in-out;
  display: block;
}

.logo:focus {
  outline-offset: 10px;
  outline-color: #00000080;
}

.nav-menu {
  gap: 32px;
  padding-left: 150px;
}

@media (width <= 1200px) {
  .nav-menu {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 30px;
    padding-left: 0;
  }
}

@media (height <= 600px) {
  .nav-menu {
    overflow-y: scroll;
  }
}

.link {
  white-space: nowrap;
  color: var(--c-black);
  background-color: #0000;
  border-radius: 20px;
  outline: 2px solid #0000;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  transition: all .3s ease-in-out;
  display: block;
}

.link:focus {
  outline-offset: 10px;
  outline-color: #00000080;
}

@media (hover: hover) and (pointer: fine) {
  .link:hover {
    text-decoration: underline 1px #000;
  }
}

.link.current {
  text-decoration: underline;
}

@media (width <= 1200px) {
  .link {
    padding: 10px 20px;
    font-size: 24px;
  }
}

.nav-actions {
  gap: 14px;
}

@media (width <= 1200px) {
  .nav-actions {
    flex-direction: column;
    align-items: center;
  }
}

.footer__menu {
  text-transform: uppercase;
  justify-content: flex-end;
  width: 100%;
}

@media (width <= 992px) {
  .footer__menu {
    justify-content: center;
  }
}

.footer__menu li:first-child {
  margin-inline: auto;
}

@media (width <= 992px) {
  .footer__menu li:first-child {
    margin-inline: initial;
  }
}

.footer-top {
  gap: 30px;
}

.footer-menu {
  gap: calc(1.66667vw + 24px);
  margin-right: 60px;
}

@media (width >= 1440px) {
  .footer-menu {
    gap: 48px;
  }
}

@media (width <= 1200px) {
  .footer-menu {
    margin-right: 0;
  }
}

.footer-bottom {
  border-top: 1px solid #fff;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-inline: calc(5.27778vw - 19px);
}

@media (width >= 1440px) {
  .footer-bottom {
    padding-inline: 57px;
  }
}

@media (width <= 992px) {
  .footer-bottom {
    flex-direction: column;
    justify-content: center;
  }
}

.copy {
  white-space: nowrap;
}

@media (width <= 600px) {
  .copy {
    white-space: wrap;
    text-align: center;
    line-height: 1.3;
  }
}
/*# sourceMappingURL=main.css.map */
