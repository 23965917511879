@use "./../../../scss/utils/functions/"as*;
@use "./../../../scss/utils/mixins/"as*;


.policy {

  .container {
    max-width: 1246px;
  }

  h1 {
    text-align: center;
    @include size('margin-bottom', 60, 30);
    font-weight: 500;
    @include size('font-size', 40, 30)
  }


  &__content {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 48px;

    h2 {
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 20px;
      li {
        // list-style-type: disc;
        &::marker {
          content: '✓ ';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

}