@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@import '../base/global-vars';

$fzXXL: $title-xxl;
$fzXL: $title-xl;
$fzLG: $title-lg;
$fzMD: $title-md;
$fzSM: $title-sm;
$fzXS: $title-xs;
$fzXXS: $title-xxs;

.title {

  &-xxl {
    @include size('font-size', $fzXXL, $rfzXXL);
  }

  &-xl {
    @include size('font-size', $fzXL, $rfzXL);
  }

  &-lg {
    @include size('font-size', $fzLG, $rfzLG);
  }

  &-md {
    @include size('font-size', $fzMD, $rfzMD);
  }

  &-sm {
    font-size: #{$rfzSM}px;
    @include rmax($sm) {
      font-size: $rfzSM;
    }
  }

  &-xs {
    font-size: #{$rfzXS}px;
    @include rmax($xs) {
      font-size: $rfzXS;
    }
  }

  &-xxs {
    font-size: #{$rfzXXS}px;
  }
}