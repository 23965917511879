@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.subscribe {
  height: 490px;
  padding-block: 129px;
  background-image: url('../assets/images/home/get.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .main-title {

  }

  @include rmax($lg) {
    height: initial;
    padding-block: 60px;
  }

  .inner {
    @include rmax($lg) {
      justify-content: center;
    }
  }



  .content {
    max-width: 488px;
    margin-right: 60px;
    @include rmax($lg) {
      max-width: max-content;
      padding: 16px;
      background-color: rgba(#fff, 0.7);
      border-radius: 8px;
      margin-right: 0;
    }
  }

  label {
    font-size: 24px;
  }

  .form-group {
    flex-wrap: nowrap;
    @include rmax($lg) {
      flex-wrap: wrap;
    }
  }

  .input {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
    padding: 9px 16px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #8d8d8d;
    width: 278px;

    @include rmax($lg) {
      width: auto;
    }
  }

  .btn {

    font-size: 24px !important;
  }
}