
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
// ситуативні стилі та класи під проєкт


.main-top {
  padding-top: 72px;
}

.title,
.main-title,
.card__title {
  font-family: var(--font-family-secondary);
  @include size('font-size', 32, 24);
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}

.fz-20 {
  font-size: 20px;
}

.fz-40 {
  font-size: 40px;
  @include rmax($lg) {
    font-size: 30px;
  }
}


.link-arrow,
.icon-arrow,
.btn-arrow {
  display: flex;
  gap: 5px;
  align-items: center;
  text-transform: uppercase;
  &::after {
    content: '';
    background-color: #000;
    display: block;
    width: 12px;
    height: 12px;
    mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.855 13.4573L0.832275 12.4431L11.3152 1.95166H2.92887L2.94591 0.54541H13.7357V11.3522H12.3124L12.3294 2.97439L1.855 13.4573Z' fill='black'/%3E%3C/svg%3E%0A");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    transition: 0.3s ease-in-out;
  }

  @include hover {
    &::after {
      transform: rotate(45deg);
    }
  }
}

.btn-arrow.btn-black,
.link-arrow-white {
  &::after {
    background-color: #fff;
  }
}

.bb-1 {
  border-bottom: 1px solid #000;
}

.bg-card {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(526 / 652 * 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  @include rmax($md) {
    padding-top: 270px;
  }

  &:hover {

    .card__content {
      opacity: 1;
    }
  }

  .card__content {
    opacity: 0;
    inset: 0;
    background-color: rgba(#000, 0.5);
    position: absolute;
    z-index: 10;
    transition: 0.3s ease-in-out;
  }
}