@use "./../../scss/utils/functions/"as*;
@use "./../../scss/utils/mixins/"as*;

.hero-img {

  .inner {
    @include rmax($xl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include rmax($lg) {
      grid-template-columns: 1fr;
    }
  }

  .content {
    @include rmax($lg) {
      gap: 0;
    }
  }

  .main-title {
    @include size('font-size', 42, 24);
    line-height: 1.45;
    @include size('margin-bottom', 24, 16);
  }

  .main-text {
    max-width: 400px;
    @include size('margin-bottom', 24, 16);
  }

  .image {
    @include rmax($lg) {
      height: 250px;
      object-fit: cover;
    }
  }
}

section {

  .head {
    @include rmax($lg) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .link-arrow {
      @include rmax($lg) {
      margin: 0;
      }
    }
  }

  .card__title + .card__link {
    padding-bottom: 21px;
  }

  .back-link {
    @include rmax($lg) {
      margin-right: auto;
    }
  }
}
