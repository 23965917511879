/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1312px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  // --container-value: 1230;

  // TODO: формула для розрахунку колонок
  // --col-width: calc(100% / 12);
  // --col-gap: 24px;

  --header-indents: 15px;
  // --main-icon-size: 40px;

  // fonts
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Releway", sans-serif;

  // line-height
  --lineHeight: 1;
  --letterSpacing: 0;

  // font-weight
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  // main-font-size
  --fz-text-normal: 16px;

  // colors
  --c-white:  #ffffff;
  --c-black:  #000;

  --c-primary: #000;
  --c-secondary: #000;

  --c-light: #FFFFF6; // light-green
  --c-dark: #000; // dark-green
  --c-gray: #8D8D8D;

  --c-accent: #000;


  // --c-green: #A3D16C;
  // --c-pink: #FF007A;

  //gradients
  --main-gradient: linear-gradient(89.93deg, #FFFFFF 0.04%, rgba(153, 153, 153, 0) 86.36%);
  // --dark-gradient: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, #161C2D 99.54%);

}





