@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.footer {

  &__menu {
    text-transform: uppercase;
    width: 100%;
    justify-content: flex-end;
    @include rmax($lg) {
      justify-content: center;
    }

    li:first-child {
      margin-inline: auto;
      @include rmax($lg) {
        margin-inline: initial;
      }
    }
  }
}

.footer-top {
  gap: 30px;
}

.footer-menu {
  @include size('gap', 48, 30);
  margin-right: 60px;
  @include rmax($xl) {
    margin-right: 0;
  }
}

.footer-bottom {
  width: 100%;
  align-items: center;
  gap: 20px;
  border-top: 1px solid #fff;
  @include size('padding-inline', 57, 0);
  @include rmax($lg) {
    flex-direction: column;
    justify-content: center;
  }
}

.copy {
  white-space: nowrap;
  @include rmax($sm) {
    white-space: wrap;
    line-height: 1.3;
    text-align: center;
  }
}