@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.s-services {

  .list {

    & > li {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  .card {
    @include rmax($md) {
      flex-direction: column;
    }

    &__inner {
      display: flex;
      gap: 40px;

      @include rmax($lg) {
        gap: 20px;
        flex-direction: column;
      }
    }

    &__image {
      object-fit: contain;
      object-position: top center;
      @include rmax($md) {
        width: 300px;
      }
    }

    &__title,
    &__text {
      margin-bottom: 21px;
    }

    &__content {
      min-width: 310px;
      @include rmax($md) {
        min-width: 100px;
      }
    }

    &__description {
      max-width: 240px;
      @include rmax($lg) {
        max-width: 100%;
      }
    }

    &__list {
      padding-top: 50px;
      @include rmax($lg) {
        padding-top: 0;
        padding-left: 20px;
      }
      li {
        list-style-type:disc;
        margin-bottom: 10px;
      }

    }
  }
}